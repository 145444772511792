<template>
  <div>
    <b-modal
      ref="modalDataCriteriosCrypto"
      :title="$t('Critérios de aplicabilidade CryptoHouses')"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="true"
      :ok-title="$t('Fechar')"
      ok-variant="outline-secondary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <p> {{ $t('Apenas são exportados os imóveis para Venda e que estejam nos estados Ativos, Reservados e Vendidos nos últimos 45 dias (a contabilizar a partir da data de venda do imóvel). A publicação do preço do imóvel é obrigatória (exceto no caso dos imóveis Reservados e Vendidos).') }} </p>

      <p> {{ $t('Caso a angariação pertença a um empreendimento, apenas são elegíveis as angariações definidas como ‘Imóveis em destaque’ (no separador ‘Grupos’).') }} </p>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
  },
  data() {
    return {
    }
  },
  computed: {
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataCriteriosCrypto.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.$refs.modalDataCriteriosCrypto.hide()
    },
  },
  setup() {
    const { skin } = useAppConfig()

    return {
      skin,
    }
  },
}
</script>
